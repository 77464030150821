import { SVGProps } from "../types";

const SoundcloudIcon: React.FC<SVGProps> = ({ color, size }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    color={color}
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M17.655,366.345L17.655,366.345C7.945,366.345,0,358.4,0,348.69v-35.31
		c0-9.71,7.945-17.655,17.655-17.655s17.655,7.945,17.655,17.655v35.31C35.31,358.4,27.366,366.345,17.655,366.345"
    />
    <path
      fill={color}
      d="M88.276,366.345L88.276,366.345c-9.71,0-17.655-7.945-17.655-17.655v-70.621
		c0-9.71,7.945-17.655,17.655-17.655s17.655,7.945,17.655,17.655v70.621C105.931,358.4,97.986,366.345,88.276,366.345"
    />
    <path
      fill={color}
      d="M158.897,366.345L158.897,366.345c-9.71,0-17.655-7.945-17.655-17.655V242.759
		c0-9.71,7.945-17.655,17.655-17.655c9.71,0,17.655,7.945,17.655,17.655V348.69C176.552,358.4,168.607,366.345,158.897,366.345"
    />
    <path
      fill={color}
      d="M229.517,366.345L229.517,366.345c-9.71,0-17.655-9.71-17.655-22.069V211.862
		c0-12.359,7.945-22.069,17.655-22.069c9.71,0,17.655,9.71,17.655,22.069v132.414C247.172,356.634,239.228,366.345,229.517,366.345"
    />
    <path
      fill={color}
      d="M450.207,366.345c34.428,0,61.793-27.366,61.793-61.793s-27.366-61.793-61.793-61.793
		c-7.062,0-13.241,0.883-20.303,3.531c-10.593-57.379-60.91-100.634-120.938-100.634c-8.828,0-17.655,0.883-26.483,2.648v218.041
		h102.4H450.207z"
    />
  </svg>
);

export default SoundcloudIcon;
